module.exports = {
    getRandom: function(arr, n) {
        var len = arr.length;
        if (n > len)
            n = len;
        var result = new Array(n),
            taken = new Array(len);
        while (n--) {
            var x = Math.floor(Math.random() * len);
            result[n] = arr[x in taken ? taken[x] : x];
            taken[x] = --len in taken ? taken[len] : len;
        }
        return result;
    }
}