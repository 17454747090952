import { StaticQuery, graphql } from "gatsby"
import { Link } from "gatsby-plugin-intl";
import FeaturedListingProperty from './FeaturedListingProperty'
import {getRandom} from './randomElementsGenerator'
import React, { useState, useEffect } from "react"
import { useIntl } from "gatsby-plugin-intl";


import propertiesData from "../../../../build_data/properties.json"

const FeaturedListingProperties = ({ data }) =>  {
    const [randomProperties, setrandomProperties] = useState([]);
    const intl = useIntl();


    useEffect(() => {
        setrandomProperties(getRandom(propertiesData.filter(p => p.is_featured && p.published), 3).map(p => { 
            let element = null
            if(p.images.length > 0){
                element = <FeaturedListingProperty property={p} key={p.id} intl={intl}></FeaturedListingProperty>
            }      
            return element
        }));
    }, [])

        return (
            <section className="feature-section spad">
                <div className="container">
                    <div className="section-title text-center">
                        <h3>{intl.formatMessage({ id:'Featured-listings'})}</h3>
                        <p><Link to={"/property-sales/#extras=is_featured"} >{intl.formatMessage({ id:'Featured-listings-subtitle'})}</Link></p>
                    </div>
                    <div className="row">
                    {
                        randomProperties
                    }
                    </div>
                </div>
            </section>  
        )
}

export default FeaturedListingProperties